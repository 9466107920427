// React imports.
import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Switch, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Editor, EditorTools } from "@progress/kendo-react-editor";

// Custom component imports.
import { InsertBrTool } from "./InsertTools.js";

const { Bold, Italic, Underline, 
    OrderedList, UnorderedList, Link, 
    Unlink, ViewHtml, ForeColor, BackColor,
    FontSize, FontName, FormatBlock 
} = EditorTools;

const minValueValidator = value =>
    value >= 0 ? "" : "The value must be 0 or higher";

const NonNegativeNumericInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <NumericTextBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

const LabelEditor = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Editor
                tools={[
                    [Bold, Italic, Underline],
                    [ForeColor, BackColor],                  
                    FontSize, FontName, FormatBlock,
                    [OrderedList, UnorderedList],
                    [Link, Unlink],
                    [InsertBrTool],                  
                    [ViewHtml]
                ]} 
                required={!optional} 
                {...others} 
            />                        
        </div>      
    );
};

const LabelInput = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Input required={!optional} {...others} />                
        </div>      
    );
};

const ActiveSwitch = (fieldRenderProps) => {
    const { value, optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>           
            <Switch
                checked = {value} 
                onLabel={"Yes"} 
                offLabel={"No"}
                {...others}
            />          
        </div>      
    );
};

const OverviewsEditForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.item}
            render={formRenderProps => (
                <FormElement className = "md:mx-8 md:my-8 max-w-screen-sm grid">
                    <fieldset className={"k-form-fieldset"}>
                        <div className="mx-6 my-6 hidden md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"overviewId"}
                                component={NonNegativeNumericInput}
                                label={"Overview Id"}
                                validator={minValueValidator}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"name"}
                                component={LabelInput}
                                label={"Name"}                           
                                maxLength={50}
                                optional={false}                              
                            />
                        </div>                                            
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"text"}
                                component={LabelEditor}
                                label={"Overview"}
                                optional={true}
                                onChange={(event) => {                                  
                                    formRenderProps.onChange("text", { value: event.html });
                                }}
                            />
                        </div> 
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"active"}
                                component={ActiveSwitch}
                                label={"Active"}
                                optional={false}                             
                            />
                        </div>                                                                      
                    </fieldset>

                    <div className="k-form-buttons mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6 k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                           {props.submitAction}
                        </button>
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6"
                            onClick={props.cancelEdit}
                        >
                            Cancel
                        </button>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default OverviewsEditForm;