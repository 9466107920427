// React imports.
import React from "react";
import { withRouter } from "react-router-dom";

// Kendo UI component imports. 
import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-react-layout";

// Custom component imports.
import { Header } from "./Header.js";

const items = [
    // { name: "Dashboard", iconSvg: "k-i-window", selected: true , route: "/" },
    { name: "Sections", iconSvg: "k-i-folder", selected: true, route: "/" },
    { name: "Types", iconSvg: "k-i-aggregate-fields", route: "/types" },
    { name: "Items", iconSvg: "k-i-file-txt", route: "/items" },
    { name: "Notifications", iconSvg: "k-i-notification", route: "/notifications" },
    { name: "Disclaimers", iconSvg: "k-i-info", route: "/disclaimers" },
    { name: "Overviews", iconSvg: "k-i-comment", route: "/overviews" },    
    { name: "Search History", iconSvg: "k-i-user", route: "/searchHistory" }   
    // { name: "Users", iconSvg: "k-i-user", route: "/users" }
];

const CustomDrawerItem = (props) => {
    const { iconSvg, text, ...others } = props;
    return (
        <DrawerItem {...others}>
            <span className={"k-icon " + iconSvg} />
            <span className="k-item-text">{text}</span>
        </DrawerItem>
    );
};

class DrawerRouterContainer extends React.Component {
    state = {
        expanded: true,
        selectedId: items.findIndex(x => x.selected === true),
        isSmallerScreen: window.innerWidth < 768
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeWindow)
        this.resizeWindow()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow)
    }

    resizeWindow = () => {
        this.setState({ isSmallerScreen: window.innerWidth < 768 })
    }

    handleClick = () => {
        this.setState((e) => ({expanded: !e.expanded}));
    }

    handleSelect = (e) => {
        this.setState({selectedId: e.itemIndex, expanded: false});
        this.props.history.push(e.itemTarget.props.route);
    }

    getSelectedItem = (pathName) => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath.name) {
            return currentPath.name;
        }
    }

    render() {
        let selected = this.getSelectedItem(this.props.location.pathname);      

        return (
             <React.Fragment>
                <Header
                    onButtonClick={this.handleClick}
                    page={selected}
                />
                <Drawer
                    expanded={this.state.expanded}
                    items={items.map((item) => ({
                                ...item,
                                text: item.name,
                                selected: item.name === selected
                            }))
                    }
                    item={CustomDrawerItem}
                    position="start"
                    mode={this.state.isSmallerScreen ? "overlay" : "push"}
                    mini={this.state.isSmallerScreen ? false : true}

                    onOverlayClick={this.handleClick}
                    onSelect={this.handleSelect}
                >
                    <DrawerContent>
                        {this.props.children}
                    </DrawerContent>
                </Drawer>
        </React.Fragment>
        );
    }
};

export default withRouter(DrawerRouterContainer);