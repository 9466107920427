// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import 'firebase/compat/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDle1UI_d3T6FuHb6KoYUwir4h44iYm6mM",
  authDomain: "greenbook-366811.firebaseapp.com",
  projectId: "greenbook-366811",
  storageBucket: "greenbook-366811.appspot.com",
  messagingSenderId: "834351854330",
  appId: "1:834351854330:web:4089ef5929d6aa08a00f43",
  measurementId: "G-HQ2M1EVCEK"
};

// Initialize Firebase
const firebaseInit = firebase.initializeApp(firebaseConfig);
const authPovider = firebase.auth.EmailAuthProvider.PROVIDER_ID;
const authSignInMethod = firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD;
const storage = getStorage(firebaseInit);

export { storage, firebaseInit, authPovider, authSignInMethod };