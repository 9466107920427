// React imports.
import React, {useState, useEffect} from "react";

// Kendo UI component imports.
import { Grid, GridToolbar, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// React resize detector imports.
import ReactResizeDetector from "react-resize-detector";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";
import CheckboxCommandCell from "../components/CheckboxCommandCell";
import AddNewCommandCell from "../components/AddNewCommandCell";
import EditCommandCell from "../components/EditCommandCell";
import SectionTypeCommandCell from "../components/SectionTypeCommandCell";
import FormatCommandCell from "../components/FormatCommandCell";
import ItemsEditForm from "../components/ItemsEditForm";

const Items = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialAllowedExtensions = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialOpenFormValue = false;
  const initialDisplayUpload = false;
  const initialDisplaySuccessMessage = false;
  const initialEditItemValue = {};
  const initialFormatDataSettings = [];
  const initialTypesDataSettings = [];
  
  const [openForm, setOpenForm] = useState(initialOpenFormValue);
  const [displayUpload, setDisplayUpload] = useState(initialDisplayUpload);
  const [allowedExtensions, setAllowedExtensions] = useState(initialAllowedExtensions);
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [editItem, setEditItem] = useState(initialEditItemValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [itemsData, setItemsData] = useState(initialDataSettings);
  const [formatData, setFormatData] = useState(initialFormatDataSettings);
  const [sectionTypesData, setSectionTypesData] = useState(initialTypesDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
 
  useEffect (() => {
    let mounted = true;  

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getItemsUrl = "https://api.compliance.iaaonline.net/Admin/GetItemsList";
    const getFormatsUrl = "https://api.compliance.iaaonline.net/Admin/GetFormatsList";
    const getSectionTypesUrl = "https://api.compliance.iaaonline.net/Admin/GetSectionTypesDDL";

    axios.all([
      axios.get(getItemsUrl, headers), 
      axios.get(getFormatsUrl, headers),
      axios.get(getSectionTypesUrl, headers)
    ])
    .then(axios.spread((...responseList) => {
        if (mounted) {                                                           
          setItemsData(responseList[0].data);
          setFormatData(responseList[1].data);
          setSectionTypesData(responseList[2].data);
                         
          setDataLoading(false);
        }
    }))
    .catch((error) => {                
        console.log(error);
    });
           
    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setItemsData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(itemsData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  const enterEdit = (item) => {
    if (item.format !== 1) {    
      const headers = {
        headers:{
          "ngrok-skip-browser-warning": "skip"       
        }
      };

      const getItemFileUrl = "https://api.compliance.iaaonline.net/Admin/GetItemFileForItem";
  
      axios.get(getItemFileUrl, headers, {params: {itemId: item.itemId}})
      .then((response) => {
          item.fileName = response.data === "" ? "" : response.data.fileName;        
      })
      .catch((error) => {                
          console.log(error);
      });

      setDisplayUpload(true);

      if (item.format === 2) {
        setAllowedExtensions([".pdf"]);
      } else if (item.format === 3) {
        setAllowedExtensions([".jpg"]);
      } else if (item.format === 4) {
        setAllowedExtensions([".png"]);
      } else if (item.format === 5) {
        setAllowedExtensions([".avi"]);
      } else if (item.format === 6) {
        setAllowedExtensions([".mp4"]);
      } else {
        setAllowedExtensions([".wmv"]);
      }
    } else {     
      setDisplayUpload(false);
      setAllowedExtensions([]);
    }

    setOpenForm(true);
    setEditItem(item);
  };

  const enterNew = () => {
    const item = {
      fileName: "",
      itemId: 0,
      sectionTypeId: "",
      name: "",
      description: "",
      overviewText: "",
      format: "",
      active: true
    };

    setDisplayUpload(false);
    setAllowedExtensions([]);
    setOpenForm(true);
    setEditItem(item);    
  };

  const updateDisplayUploadState = (displayState) => {
    setDisplayUpload(displayState);
  }

  const updateAllowedExtensionsState = (allowedExtensionsState) => {
    setAllowedExtensions(allowedExtensionsState);
  }

  const handleSubmit = (event) => {
    // Seting format value that should be passed to the database.
    event.sectionTypeName = typeof event.sectionTypeId === "object" ? event.sectionTypeId.item : event.sectionTypeName;
    event.sectionTypeId = typeof event.sectionTypeId === "object" ? event.sectionTypeId.id : event.sectionTypeId;
    event.format = typeof event.format === "object" ? event.format.formatId : event.format;
    
    const itemFile = {
      itemFileId: 0
    }; 

    if (event.itemId === 0) {
      const headers = {
        headers:{
          "ngrok-skip-browser-warning": "skip"       
        }
      };

      const addNewItemRecordUrl = "https://api.compliance.iaaonline.net/Admin/CreateItemRecord";
      const addNewItemFileRecordUrl = "https://api.compliance.iaaonline.net/Admin/CreateItemFileRecord";
          
      axios.post(addNewItemRecordUrl, event, headers)
      .then((response) => {         
        event.itemId = response.data.itemId;

        if (event.format !== 1) {      
          itemFile.itemId = response.data.itemId;
          itemFile.fileName = event.fileName;   
        }
        
        return axios.post(addNewItemFileRecordUrl, itemFile, headers);    
      })
      .then(()=> {
        const updatedData = itemsData;
        updatedData.push(event);
        
        setItemsData(updatedData);
        setOpenForm(false);
        setDisplaySuccessMessage(true);
      })
      .catch((error) => {                
          console.log(error);
      });
    } else {
      const headers = {
        headers:{
          "ngrok-skip-browser-warning": "skip"       
        }
      };

      const updateItemRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateItemRecord";
      const updateItemFileRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateItemFileRecord";
      
      const postUrlList = [axios.post(updateItemRecordUrl, event, headers)];

      if (event.format !== 1 && event.fileName !== "") {      
        itemFile.itemId = event.itemId;
        itemFile.fileName = event.fileName;

        postUrlList.push(axios.post(updateItemFileRecordUrl, itemFile, headers));
      }

      axios.all(postUrlList)
      .then(axios.spread(() => {                                                           
          const editedData = itemsData.map(item => {
            if (event.itemId === item.itemId) {                      
                item = { ...event};
            }

            return item;
          });
    
        setItemsData(editedData);
        setOpenForm(false);
        setDisplaySuccessMessage(true);
      }))
      .catch((error) => {                
          console.log(error);
      });
    }   
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const ItemsEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />  
  );

  const ItemsAddNewCommandCell = (props) => (
    <AddNewCommandCell {...props} enterNew={enterNew} />  
  );

  const FormatCommandCellWithData = (props) => (
    <FormatCommandCell {...props} formatData={formatData} /> 
  );

  const SectionTypeCommandCellWithData = (props) => (
    <SectionTypeCommandCell {...props} sectionTypesData={sectionTypesData} /> 
  );

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">
        <ReactResizeDetector handleWidth refreshMode="debounce">
          {({ width, targetRef }) => 
            <div ref={targetRef} className={!openForm ? "grid" : "hidden"}>
              <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Items</h2>         
              <div className="overflow-x-hidden">
                <Grid          
                  data={filterBy(itemsData.slice(skip, take + skip), filter)}
                  pageable={{pageSizes: true}}             
                  skip={skip}
                  take={take}
                  filterable={true}
                  filter={filter}
                  total={itemsData.length}
                  sortable={{allowUnsort: true, mode: "multiple"}}         
                  sort={sort}                                     
                  onSortChange={handleSortEvent}
                  onPageChange={handlePageChangeEvent}
                  onFilterChange={handleFilterChangeEvent}                         
                >
                  <GridToolbar>
                    <ItemsAddNewCommandCell/>
                  </GridToolbar>
                  <GridNoRecords></GridNoRecords>
                  <GridColumn cell={ItemsEditCommandCell} filterable={false} width={160} />
                  <GridColumn field="itemId" title="Item Id" filter="numeric" width={200} />
                  <GridColumn field="name" title="Name" width={200} />   
                  <GridColumn field="sectionTypeId" title="Section Type" filter="numeric" cell={SectionTypeCommandCellWithData} width={240} />                            
                  <GridColumn className="truncate" field="description" title="Description" width={200} />
                  <GridColumn field="overviewText" className="truncate" title="Overview" width={200} />    
                  <GridColumn field="format" title="Format" filter="numeric" cell={FormatCommandCellWithData} width={200} /> 
                  <GridColumn field="active" title="Active" filter="boolean" cell={CheckboxCommandCell} {...(width<1400 && {width:160})} />                                    
                </Grid>
              </div>
            </div>
          }
        </ReactResizeDetector>
           
        {openForm &&
          <div className="grid">
            <h2 className="font-light mx-auto mt-8 mb-4 md:mb-8 md:mt-4 md:text-3xl">
              {editItem.itemId === 0 ? "Create Item" : "Edit Item"} 
            </h2>
            <ItemsEditForm          
              cancelEdit={handleCancelEdit} 
              onSubmit={handleSubmit} 
              item={editItem}       
              formatData={formatData}
              sectionTypesData={sectionTypesData}
              updateDisplayUploadState={updateDisplayUploadState}
              updateAllowedExtensionsState={updateAllowedExtensionsState}
              displayUpload={displayUpload}
              allowedExtensions={allowedExtensions}                
              submitAction={editItem.itemId === 0 ? "Create" : "Update"} 
            />
          </div>
        }

        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>       
      </div>
    );
  }
};

export default Items;