// React imports.
import * as React from "react";

const FormatCommandCell = (props) => { 
    let sectionType = props.sectionTypesData.find(f => f.id === props.dataItem[props.field]);

    return (
      <td> 
       {sectionType.item}
      </td>
  );
};

export default FormatCommandCell;