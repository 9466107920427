// React imports.
import React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Switch, TextArea, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Upload } from "@progress/kendo-react-upload";
import { Editor, EditorTools } from "@progress/kendo-react-editor";

// Custom component imports.
import { InsertBrTool } from "./InsertTools.js";

// Firestore imports.
import { storage } from "../firebase";
import { ref, uploadBytes, deleteObject } from "firebase/storage";

const { Bold, Italic, Underline, 
    OrderedList, UnorderedList, Link, 
    Unlink, ViewHtml, ForeColor, BackColor,
    FontSize, FontName, FormatBlock 
} = EditorTools;

const minValueValidator = value =>
    value >= 0 ? "" : "The value must be 0 or higher";

const nullValueValidator = value =>
    !value ? "Please select an option" : "";

const NonNegativeNumericInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <NumericTextBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

const DescriptionTextArea = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <TextArea                               
                rows={6} 
                {...others}
            />                
        </div>      
    );
};

const FormatDropdownList = (fieldRenderProps) => { 
    const { handleFormatChange, value, formatData, optional, label, validationMessage, touched, ...others } = fieldRenderProps;   
    const defaultValue = formatData.find(e => e.formatId === value);

    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <DropDownList
                data={formatData}
                textField="formatName"
                dataItemKey="formatId"
                required={true}                                                             
                defaultValue={defaultValue}
                onChange={handleFormatChange}
                {...others}
            />
            {
                touched && validationMessage &&
                (<Error>{validationMessage}</Error>)
            }              
        </div>      
    );
};

const SectionTypesDropdownList = (fieldRenderProps) => {  
    const { value, sectionTypesData, optional, label, validationMessage, touched, ...others } = fieldRenderProps;   
    const defaultValue = sectionTypesData.find(e => e.id === value);
    
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <DropDownList
                data={sectionTypesData}
                textField="item"
                dataItemKey="id"                                           
                defaultValue={defaultValue}                                                                                                                     
                {...others}
            />
            {
                touched && validationMessage &&
                (<Error>{validationMessage}</Error>)
            }            
        </div>      
    );
};

const LabelUpload = (fieldRenderProps) => {
    const { value, handleFileRemove, handleFileAdd, allowedExtensions, optional, label, ...others } = fieldRenderProps;   
                         
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Upload           
                multiple={false}
                restrictions={{
                    allowedExtensions: allowedExtensions
                }}
                defaultFiles={[]}
                withCredentials={false}
                autoUpload={false}
                showActionButtons={false}           
                onAdd={handleFileAdd}
                onRemove={handleFileRemove}        
                {...others}
            />        
        </div>      
    );
};

const LabelInput = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Input required={!optional} {...others} />                
        </div>      
    );
};

const ActiveSwitch = (fieldRenderProps) => {
    const { value, optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>           
            <Switch
                checked = {value} 
                onLabel={"Yes"} 
                offLabel={"No"}
                {...others}
            />          
        </div>      
    );
};

const LabelEditor = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Editor
                tools={[
                    [Bold, Italic, Underline],
                    [ForeColor, BackColor],                  
                    FontSize, FontName, FormatBlock,
                    [OrderedList, UnorderedList],
                    [Link, Unlink],
                    [InsertBrTool],                  
                    [ViewHtml]
                ]} 
                required={!optional} 
                {...others} 
            />                        
        </div>      
    );
};

const ItemsEditForm = (props) => {
    const handleFormatChange = (event) => {                                               
        if (event.value.formatId !== 1) {
            props.updateDisplayUploadState(true);

            if (event.value.formatId === 2) {
                props.updateAllowedExtensionsState([".pdf"]);
            } else if (event.value.formatId === 3) {
                props.updateAllowedExtensionsState([".jpg"]);
            } else  if (event.value.formatId === 4) {
                props.updateAllowedExtensionsState([".png"]);
            } else  if (event.value.formatId === 5) {
                props.updateAllowedExtensionsState([".avi"]);
            } else  if (event.value.formatId === 6) {
                props.updateAllowedExtensionsState([".mp4"]);
            } else {
                props.updateAllowedExtensionsState([".wmv"]);
            }
        } else {
            props.updateDisplayUploadState(false);
            props.updateAllowedExtensionsState([]);       
        }

        return event.value.formatId;
    };

    const handleFileAddEvent = async (event) => {
        const file = event.target.files[0];   
        const rawFile = file.getRawFile();    
     
        if (props.allowedExtensions.includes(file.extension)) {                   
            const storageRef = ref(storage, file.name);
        
            uploadBytes(storageRef, rawFile).then(() => {               
            });
        }

        return file.name;
    };

    const handleFileRemove = async (event) => {     
        const file = event.affectedFiles[0];                  
        const desertRef = ref(storage, file.name); 

        deleteObject(desertRef).then(() => {}).catch(() => {});           
    };

    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.item}
            render={formRenderProps => (                        
                <FormElement className = "md:mx-8 md:my-8 max-w-screen-xl grid">
                    <fieldset className={"k-form-fieldset"}>
                        <div className="mx-6 my-6 hidden md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"itemId"}
                                component={NonNegativeNumericInput}
                                label={"Item Id"}
                                validator={minValueValidator}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm">
                            <Field
                                name={"name"}
                                component={LabelInput}
                                label={"Name"}                             
                                maxLength={100}
                                optional={false}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm">
                            <Field
                                name={"sectionTypeId"}
                                component={SectionTypesDropdownList}
                                validator={nullValueValidator}                             
                                label={"Section Type"}                            
                                sectionTypesData={props.sectionTypesData}                                                          
                                optional={false}                                                                                                                                                                                                                                                                   
                            />
                        </div>      
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm">
                            <Field
                                name={"format"}
                                component={FormatDropdownList}
                                validator={nullValueValidator} 
                                label={"Format"}                                                       
                                formatData={props.formatData}                              
                                optional={false}                                                                                                                   
                                onChange={(event) => {
                                    const updatedValue = handleFormatChange(event);
                                   
                                    formRenderProps.onChange("format", { value: updatedValue });
                                }}                                                                                                                                                                                                                                                                                                       
                            />
                        </div>
                        <div className={
                            props.displayUpload ? "mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm" 
                            : "mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm hidden"}
                        >
                            <Field
                                name={"fileName"}
                                component={LabelUpload}                          
                                value={"name"}
                                label={"File"}                                               
                                optional={false}
                                allowedExtensions={props.allowedExtensions}
                                handleFileAdd={async (event) => {
                                    const updatedValue = await handleFileAddEvent(event);
                                    
                                    formRenderProps.onChange("fileName", { value: updatedValue });
                                }}
                                handleFileRemove={handleFileRemove}                                                                                                                                                                                                                                                                                                                                    
                            />
                        </div>                                          
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3 max-w-screen-sm">
                            <Field
                                name={"description"}
                                component={DescriptionTextArea}
                                label={"Description"}                            
                                optional={true}
                            />
                        </div>               
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"overviewText"}
                                component={LabelEditor}
                                label={"Overview"}
                                optional={true}
                                onChange={(event) => {                                  
                                    formRenderProps.onChange("overviewText", { value: event.html });
                                }}
                            />
                        </div> 
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"active"}
                                component={ActiveSwitch}
                                label={"Active"}
                                optional={false}                             
                            />
                        </div>                                                                      
                    </fieldset>

                    <div className="k-form-buttons mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6 k-primary"
                            disabled={!formRenderProps.allowSubmit}                        
                        >
                           {props.submitAction}
                        </button>
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6"
                            onClick={props.cancelEdit}
                        >
                            Cancel
                        </button>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default ItemsEditForm;