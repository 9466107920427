// React imports.
import * as React from "react";

const ColorCommandCell = (props) => {
    return (
        <td style={{backgroundColor: props.dataItem[props.field]}}>         
        </td>
    );
};

export default ColorCommandCell;