import * as React from "react";

const AddNewCommandCell = (props) => {   
    return (
        <div className="py-2 px-6 flex justify-end items-end"> 
            <button
                className="k-button normal-case px-6 k-primary"
                onClick={() => props.enterNew(props.dataItem)}
            >
                Add New
            </button>
        </div>
    );
};

export default AddNewCommandCell;