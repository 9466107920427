// React imports.
import {useState, useEffect, useRef} from "react";

// Kendo UI component imports.
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridToolbar, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";

const SearchHistory = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialDisplaySuccessMessage = false;
  
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [typesData, setTypesData] = useState(initialDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
  
  useEffect (() => {
    let mounted = true;  

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getTypesUrl = "https://api.compliance.iaaonline.net/Admin/GetSearchHistoryList";
  
    axios.get(getTypesUrl, headers)
        .then((response) => {
            if (mounted) {                                                           
              setTypesData(response.data);          
              setDataLoading(false);
            }
        })
        .catch((error) => {                
            console.log(error);
        });
           

    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const exportRef = useRef(null);
  const exportDataToExcel = () => {
    if (exportRef.current !== null) {
      exportRef.current.save();
    }
  };

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setTypesData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(typesData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">
        <div className={"grid"}>
          <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Search History</h2>
          <div className="overflow-x-hidden">
            <ExcelExport data={filterBy(typesData.slice(skip, take + skip), filter)} ref={exportRef}>
              <Grid          
                data={filterBy(typesData.slice(skip, take + skip), filter)}
                pageable={{pageSizes: [10, 20, 50, 100, 300]}} 
                skip={skip}
                take={take}
                filterable={true}
                filter={filter}
                total={typesData.length}
                sortable={{allowUnsort: true, mode: "multiple"}}         
                sort={sort}
                onSortChange={handleSortEvent}
                onPageChange={handlePageChangeEvent}
                onFilterChange={handleFilterChangeEvent}               
              >
                <GridToolbar>
                  <div className="py-2 px-6 flex justify-end items-end"> 
                    <button
                      title="Export Excel"                    
                      type={"submit"}
                      className="k-button normal-case px-6 k-primary"
                      onClick={exportDataToExcel}
                    >
                      Export to Excel
                    </button>
                  </div>       
                </GridToolbar>
                <GridNoRecords></GridNoRecords>           
                {/* <GridColumn field="searchUser" title="Search User" {...(window.screen.availWidth <= 768 && {width:200})} /> */}
                <GridColumn className="truncate" field="searchString" title="Search String" {...(window.screen.availWidth <= 768 && {width:200})} />      
                <GridColumn field="searchDate" title="Search Date"  {...(window.screen.availWidth <= 768 && {width:200})} />     
              </Grid>
            </ExcelExport>
          </div>
        </div>
        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>       
      </div>
    );
  }
};
  
export default SearchHistory;