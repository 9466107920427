import * as React from "react";
import { EditorUtils } from "@progress/kendo-react-editor";
import { Button } from "@progress/kendo-react-buttons";

const createInsertTool = settings =>
    props => {
        const { view } = props;
        const nodeType = view && view.state.schema.nodes[settings.nodeType];
        const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

        const handleButtonClick = (e) => {
            e.preventDefault();
            EditorUtils.insertNode(view, nodeType.createAndFill(settings.attrs))
        };

        return (
            <Button
                onClick={handleButtonClick}
                disabled={!canInsert}
                onMouseDown={e => e.preventDefault()}
                onPointerDown={e => e.preventDefault()}             
                {...settings.props}
            />
        );
    };

const InsertBrTool = createInsertTool({
    nodeType: "hard_break",
    props: {
        icon: "table-position-left",
        title: "Insert Line Break"
    }
});

export { InsertBrTool };