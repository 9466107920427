// React imports.
import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ColorPicker, Switch, TextArea, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";

const minValueValidator = value =>
    value >= 0 ? "" : "The value must be 0 or higher";

const NonNegativeNumericInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <NumericTextBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

const DescriptionTextArea = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <TextArea                               
                rows={6} 
                {...others}
            />                
        </div>      
    );
};

const LabelInput = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Input required={!optional} {...others} />                
        </div>      
    );
};

const LabelColorPicker = (fieldRenderProps) => {
    const { value, optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <ColorPicker
                view="gradient"
                required={!optional}
                defaultValue={value}
                value={value} 
                {...others} 
            />                
        </div>      
    );
};

const ActiveSwitch = (fieldRenderProps) => {
    const { value, optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>           
            <Switch
                checked={value} 
                onLabel={"Yes"} 
                offLabel={"No"}
                {...others}
            />          
        </div>      
    );
};

const SectionsEditForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.item}
            render={formRenderProps => (
                <FormElement className = "md:mx-8 md:my-8 max-w-screen-sm grid">
                    <fieldset className={"k-form-fieldset"}>
                        <div className="mx-6 my-6 hidden md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"sectionId"}
                                component={NonNegativeNumericInput}
                                label={"Section Id"}
                                validator={minValueValidator}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"name"}
                                component={LabelInput}
                                label={"Name"}                            
                                maxLength={70}
                                optional={false}                              
                            />
                        </div>
                        <div className="mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"displayOrder"}
                                component={NonNegativeNumericInput}
                                label={"Display Order"}
                                validator={minValueValidator}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"color"}
                                component={LabelColorPicker}
                                label={"Color"}                             
                                maxLength={7}
                                optional={false}                              
                            />
                        </div>                                                
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"description"}
                                component={DescriptionTextArea}
                                maxLength={200}    
                                label={"Description"}
                                optional={true}
                            />
                        </div>                      
                        <div className="md:mx-0 md:mt-0 md:mb-3 md:flex md:space-x-6">
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field                             
                                    name={"active"}
                                    component={ActiveSwitch}
                                    label={"Active"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field                             
                                    name={"overviewActive"}
                                    component={ActiveSwitch}
                                    label={"Introduction"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field                              
                                    name={"articlesActive"}
                                    component={ActiveSwitch}
                                    label={"Information"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"formsActive"}
                                    component={ActiveSwitch}
                                    label={"Forms"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"policiesActive"}
                                    component={ActiveSwitch}
                                    label={"Sample Policies"}
                                    optional={false}                             
                                />
                            </div>                     
                        </div>
                        <div className="md:mx-0 md:mt-0 md:mb-3 md:flex md:space-x-6">                      
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"videosActive"}
                                    component={ActiveSwitch}
                                    label={"Videos"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"statusesActive"}
                                    component={ActiveSwitch}
                                    label={"Statuses"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"resourcesActive"}
                                    component={ActiveSwitch}
                                    label={"Resources"}
                                    optional={false}                             
                                />
                            </div>
                            <div className="my-6 mx-6 md:mx-0 md:my-0 md:w-1/6">
                                <Field
                                    name={"faqActive"}
                                    component={ActiveSwitch}
                                    label={"FAQ"}
                                    optional={false}                             
                                />
                            </div>                        
                        </div>                                                                                                           
                    </fieldset>

                    <div className="k-form-buttons mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6 k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                           {props.submitAction}
                        </button>
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6"
                            onClick={props.cancelEdit}
                        >
                            Cancel
                        </button>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default SectionsEditForm;