// React imports.
import React, {useState, useEffect} from "react";

// Kendo UI component imports.
import { Grid, GridToolbar, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// React resize detector imports.
import ReactResizeDetector from "react-resize-detector";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";
import ColorCommandCell from "../components/ColorCommandCell";
import CheckboxCommandCell from "../components/CheckboxCommandCell";
import AddNewCommandCell from "../components/AddNewCommandCell";
import EditCommandCell from "../components/EditCommandCell";
import SectionsEditForm from "../components/SectionsEditForm";

const Sections = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialOpenFormValue = false;
  const initialDisplaySuccessMessage = false;
  const initialEditItemValue = {};
  
  const [openForm, setOpenForm] = useState(initialOpenFormValue);
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [editItem, setEditItem] = useState(initialEditItemValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [sectionsData, setSectionsData] = useState(initialDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
  
  useEffect (() => {
    let mounted = true;  

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getSectionsUrl = "https://api.compliance.iaaonline.net/Admin/GetSectionsList";
  
    axios.get(getSectionsUrl, headers)
        .then((response) => {
            if (mounted) {                                                           
              setSectionsData(response.data);
              setDataLoading(false);
            }
        })
        .catch((error) => {                
            console.log(error);
        });
           

    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setSectionsData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(sectionsData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);  
  };

  const enterNew = () => {
    const item = {
      sectionId: 0,
      name: "",
      description: "",
      color: "#3f51b5",
      active: true,
      overviewActive: false,
      articlesActive: false,
      formsActive: false,
      videosActive: false,
      statusesActive: false,
      resourcesActive: false,
      faqActive: false,
      policiesActive: false,
    };

    setOpenForm(true);
    setEditItem(item);  
};

  const handleSubmit = (event) => {
    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    if (event.sectionId === 0) {
      const addNewSectionRecordUrl = "https://api.compliance.iaaonline.net/Admin/CreateSectionRecord";
    
      axios.post(addNewSectionRecordUrl, event, headers)
          .then((response) => {          
            event.sectionId = response.data.sectionId;
            
            const updatedData = sectionsData;
            updatedData.push(event);
            
            setSectionsData(updatedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    } else {
      const updateSectionRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateSectionRecord";
    
      axios.post(updateSectionRecordUrl, event, headers)
          .then(() => {                                                                      
              const editedData = sectionsData.map(item => {
                if (event.sectionId === item.sectionId) {
                    item = { ...event };
                }

                console.log(item);
    
                return item;
              });
        
            setSectionsData(editedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    }   
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const SectionsEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />  
  );

  const SectionsAddNewCommandCell = (props) => (
    <AddNewCommandCell {...props} enterNew={enterNew} />  
  );

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">
        <ReactResizeDetector handleWidth>
          {({ width, targetRef }) => 
            <div ref={targetRef} className={!openForm ? "grid" : "hidden"}>
              <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Sections</h2>
              <div className="overflow-x-hidden">
                <Grid          
                  data={filterBy(sectionsData.slice(skip, take + skip), filter)}
                  pageable={{pageSizes: true}} 
                  skip={skip}
                  take={take}
                  filterable={true}
                  filter={filter}
                  total={sectionsData.length}
                  sortable={{allowUnsort: true, mode: "multiple"}}         
                  sort={sort}
                  onSortChange={handleSortEvent}
                  onPageChange={handlePageChangeEvent}
                  onFilterChange={handleFilterChangeEvent}                         
                >
                  <GridToolbar>
                    <SectionsAddNewCommandCell/>
                  </GridToolbar>
                  <GridNoRecords></GridNoRecords>
                  <GridColumn cell={SectionsEditCommandCell} filterable={false} width={160} />           
                  <GridColumn field="sectionId" title="Section Id" filter="numeric" width={200} />
                  <GridColumn field="name" title="Name" width={200} />         
                  <GridColumn className="truncate" field="description" title="Description" width={200} />  
                  <GridColumn field="color" title="Color" filterable={false} width={160} cell={ColorCommandCell} />
                  <GridColumn field="displayOrder" title="Display Order" filter="numeric" width={160} />  
                  <GridColumn field="active" title="Active" filter="boolean" cell={CheckboxCommandCell} width={160}/>
                  <GridColumn field="overviewActive" title="Overview" filter="boolean" cell={CheckboxCommandCell} width={160} />
                  <GridColumn field="articlesActive" title="Articles" filter="boolean" cell={CheckboxCommandCell} width={160} /> 
                  <GridColumn field="formsActive" title="Forms" filter="boolean" cell={CheckboxCommandCell} width={160} />          
                  <GridColumn field="videosActive" title="Videos" filter="boolean" cell={CheckboxCommandCell} width={160} />
                  <GridColumn field="statusesActive" title="Citations" filter="boolean" cell={CheckboxCommandCell} width={160} />
                  <GridColumn field="resourcesActive" title="Resources" filter="boolean" cell={CheckboxCommandCell} width={160} />
                  <GridColumn field="faqActive" title="FAQ" filter="boolean" cell={CheckboxCommandCell} {...(width<2360 && {width:160})} />
                  <GridColumn field="policiesActive" title="Sample Policies" filter="boolean" cell={CheckboxCommandCell} {...(width<2360 && {width:160})} />                                
                </Grid>
              </div>
            </div>
          }
        </ReactResizeDetector>
      
        {openForm &&
          <div className="grid">
            <h2 className="font-light mx-auto mt-8 mb-4 md:mb-8 md:mt-4 md:text-3xl">
              {editItem.sectionId === 0 ? "Create Section" : "Edit Section"} 
            </h2>
            <SectionsEditForm          
              cancelEdit={handleCancelEdit} 
              onSubmit={handleSubmit} 
              item={editItem}
              submitAction={editItem.sectionId === 0 ? "Create" : "Update"} 
            />
          </div>
        }

        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>           
      </div>
    );
  }
};

export default Sections;