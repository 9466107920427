// React imports.
import * as React from "react";

const CheckboxCommandCell = (props) => {
    return (
      <td>
        <input type="checkbox" checked={props.dataItem[props.field]} disabled="disabled" />
      </td>
  );
};

export default CheckboxCommandCell;