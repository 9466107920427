// React imports.
import React, {useState, useEffect} from "react";

// Kendo UI component imports.
import { Grid, GridToolbar, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// React resize detector imports.
import ReactResizeDetector from "react-resize-detector";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";
import CheckboxCommandCell from "../components/CheckboxCommandCell";
import AddNewCommandCell from "../components/AddNewCommandCell";
import EditCommandCell from "../components/EditCommandCell";
import OverviewsEditForm from "../components/OverviewsEditForm";

const Overview = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialOpenFormValue = false;
  const initialDisplaySuccessMessage = false;
  const initialEditItemValue = {};
  
  const [openForm, setOpenForm] = useState(initialOpenFormValue);
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [editItem, setEditItem] = useState(initialEditItemValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [overviewsData, setOverviewsData] = useState(initialDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
  
  useEffect (() => {   
    let mounted = true;  

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getOverviewsUrl = "https://api.compliance.iaaonline.net/Admin/GetOverviewsList";
  
    axios.get(getOverviewsUrl, headers)
        .then((response) => {
            if (mounted) {                                                           
              setOverviewsData(response.data);
              console.log(response.data);
              setDataLoading(false);         
            }
        })
        .catch((error) => {                
            console.log(error);
        });
           
    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setOverviewsData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(overviewsData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);  
  };

  const enterNew = () => {
    const item = {
      overviewId: 0,
      name: "",
      imagePath: "",
      link: "",
      text: "",
      active: true
    };

    setOpenForm(true);
    setEditItem(item);  
};

  const handleSubmit = (event) => {
    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    if (event.overviewId === 0) {
      const addNewOverviewRecordUrl = "https://api.compliance.iaaonline.net/Admin/CreateOverviewRecord";
    
      axios.post(addNewOverviewRecordUrl, event, headers)
          .then((response) => {     
            event.overviewId = response.data.overviewId;
            
            const updatedData = overviewsData;
            updatedData.push(event);
            
            setOverviewsData(updatedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    } else {
      const updateOverviewRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateOverviewRecord";
    
      axios.post(updateOverviewRecordUrl, event, headers)
          .then(() => {                                                           
              const editedData = overviewsData.map(item => {
                if (event.overviewId === item.overviewId) {
                    item = { ...event };
                }
    
                return item;
              });
        
            setOverviewsData(editedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    }   
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  
  const OverviewsEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />  
  );

  const OverviewsAddNewCommandCell = (props) => (
    <AddNewCommandCell {...props} enterNew={enterNew} />  
  );

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">     
        <ReactResizeDetector handleWidth refreshMode="debounce">
          {({ width, targetRef }) => 
            <div ref={targetRef} className={!openForm ? "grid" : "hidden"}>      
              <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Overviews</h2>        
              <div className="overflow-x-hidden">
                <Grid             
                  data={filterBy(overviewsData.slice(skip, take + skip), filter)}
                  pageable={{pageSizes: true}} 
                  skip={skip}
                  take={take}
                  filterable={true}
                  filter={filter}
                  total={overviewsData.length}
                  sortable={{allowUnsort: true, mode: "multiple"}}         
                  sort={sort}
                  onSortChange={handleSortEvent}
                  onPageChange={handlePageChangeEvent}
                  onFilterChange={handleFilterChangeEvent}              
                >
                  <GridToolbar>
                    <OverviewsAddNewCommandCell/>
                  </GridToolbar>
                  <GridNoRecords></GridNoRecords>
                  <GridColumn cell={OverviewsEditCommandCell} filterable={false} {...(window.screen.availWidth <= 768 && {width:160})} />                 
                  <GridColumn field="overviewId" title="Overview Id" filter="numeric" {...(window.screen.availWidth <= 768 && {width:200})} />
                  <GridColumn field="name" title="Name" {...(window.screen.availWidth <= 768 && {width:200})} />                          
                  <GridColumn className="truncate" field="text" title="Text" {...(window.screen.availWidth <= 768 && {width:200})} />
                  <GridColumn field="active" title="Active" filter="boolean" cell={CheckboxCommandCell} {...(window.screen.availWidth <= 768 && {width:200})}/>                                     
                </Grid>
              </div>
            </div>
          }
        </ReactResizeDetector>
      
        {openForm &&
          <div className="grid">
            <h2 className="font-light mx-auto mt-8 mb-4 md:mb-8 md:mt-4 md:text-3xl">
              {editItem.overviewId === 0 ? "Create Overview" : "Edit Overview"} 
            </h2>
            <OverviewsEditForm          
              cancelEdit={handleCancelEdit} 
              onSubmit={handleSubmit} 
              item={editItem}
              submitAction={editItem.overviewId === 0 ? "Create" : "Update"} 
            />
          </div>
        }

        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>         
      </div>
    );
  }
};
  
export default Overview;