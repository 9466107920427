// React imports.
import * as React from "react";

const EditCommandCell = (props) => {   
    return (
        <td>
            <button
                className="k-button normal-case px-6 k-primary"
                onClick={() => props.enterEdit(props.dataItem)}
            >
               Edit
            </button>
        </td>
    );
};

export default EditCommandCell;