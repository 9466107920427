// React imports.
import {React, useEffect, useState} from "react";
import { HashRouter, Switch, Route } from "react-router-dom";

// Firebase imports.
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebaseInit, authPovider, authSignInMethod } from "./firebase";

// Routing imports.
// import Dashboard from "./pages/Dashboard.js";
import Sections from "./pages/Sections.js";
import Types from "./pages/Types.js";
import Items from "./pages/Items.js";
import Notifications from "./pages/Notifications.js";
import Disclaimers from "./pages/Disclaimers.js";
import Overviews from "./pages/Overviews";
import SearchHistory from "./pages/SearchHistory.js";
// import Users from "./pages/Users.js";

// Custom component imports.
import DrawerRouterContainer from "./components/DrawerRouterContainer.js";

// Styling imports.
import "@progress/kendo-theme-material/dist/all.css";

// Configure FirebaseUI.
const authUIConfig = { 
  signInFlow: "popup",
  signInOptions: [ 
    {
      provider: authPovider,
      signInMethod: authSignInMethod,
      disableSignUp: {
        status: true
      }    
    }
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  }
};

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = firebaseInit.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return isSignedIn ? (
    ( 
      <div>
        <HashRouter>
            <DrawerRouterContainer>
                <Switch>
                    {/* <Route exact={true} path="/" component={Dashboard} /> */}
                    <Route exact={true} path="/" component={Sections} />
                    <Route exact={true} path="/types" component={Types} />
                    <Route exact={true} path="/items" component={Items} />
                    <Route exact={true} path="/notifications" component={Notifications} />
                    <Route exact={true} path="/disclaimers" component={Disclaimers} />
                    <Route exact={true} path="/overviews" component={Overviews} />
                    <Route exact={true} path="/searchHistory" component={SearchHistory} />
                    {/* <Route exact={true} path="/users" component={Users} /> */}
                </Switch>
            </DrawerRouterContainer>
        </HashRouter>    
      </div>
    )
  ) : (
      <div className="h-screen bg-gray-200"> 
        <div className="relative top-2/4 -translate-y-1/2 sm:max-w-xl sm:mx-auto">  
          <StyledFirebaseAuth uiConfig={authUIConfig} firebaseAuth={firebaseInit.auth()} />
        </div>
      </div>
  );
};

export default (App);
