// React imports.
import React from "react";

// AWS Amplify imports.
// import { Auth } from "aws-amplify";

// Asset imports.
import headerBg from "../assets/header-bg.png";

export const Header = (props) => {
    const { onButtonClick, page } = props;  
    
    // const signOut = async () => {
    //     try {
    //         await Auth.signOut();

    //         window.location.reload();
    //     } catch (error) {
    //         console.log("Error signing out: ", error);
    //     }
    // }

    return (
        <header className="sticky top-0 h-20 z-10" 
                style={{ backgroundImage: `url(${headerBg})` }} 
        >
            <div className="flex flex-row h-full text-white items-center justify-between">
                <div className="text-center flex-grow-0 flex-shrink-0 w-12">
                    <span className={"k-icon k-i-menu w-6 text-xl cursor-pointer"} onClick={onButtonClick}/>
                </div>

                <div className="flex flex-col flex-grow flex-shrink-0 w-auto md:flex-row items-start ml-6">
                    <h1 className="font-medium md:text-2xl">Admin Console</h1>                   
                    <span className="border-l border-solid border-white md:h-8 md:mx-4"></span>
                    <h2 className="font-light md:text-2xl">{page}</h2>
                </div>
                
                 {/* <div className="mr-6">
                        <button                         
                            className="k-button normal-case md:px-9"
                            onClick={signOut}
                        >  
                        Log Out
                        </button> 
                </div>                               */}
            </div>        
        </header>
    );
};

