// React imports.
import * as React from "react";

const FormatCommandCell = (props) => { 
    let format = props.formatData.find(f => f.formatId === props.dataItem[props.field]);

    return (
      <td> 
       {format.formatName}
      </td>
  );
};

export default FormatCommandCell;