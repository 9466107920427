// React imports.
import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { TextArea, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";

const minValueValidator = value =>
    value >= 0 ? "" : "The value must be 0 or higher";

const nullValueValidator = (value) => 
    !value ? "Please enter a name." : "";

const NonNegativeNumericInput = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    return (
        <div>
            <NumericTextBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    );
};

const LabelInput = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>                 
            <Input {...others} />                
        </div>      
    );
};

const DescriptionTextArea = (fieldRenderProps) => {
    const { optional, label, ...others } = fieldRenderProps;
    return (
        <div>
            <Label optional={optional}>{label}</Label>           
            <TextArea 
                rows={8} 
                {...others}
            />          
        </div>      
    );
};

const TypesEditForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            initialValues={props.item}
            render={formRenderProps => (
                <FormElement className = "md:mx-8 md:my-8 max-w-screen-sm grid">
                    <fieldset className={"k-form-fieldset"}>
                        <div className="mb-3 mx-6 my-6 hidden md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"typeId"}
                                component={NonNegativeNumericInput}
                                label={"Type Id"}
                                validator={minValueValidator}                              
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"name"}
                                component={LabelInput}
                                label={"Name"}
                                validator={nullValueValidator}
                                maxLength={50}
                                disabled={true}
                                optional={false} 
                            />
                        </div>
                        <div className="mb-3 mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                            <Field
                                name={"description"}
                                component={DescriptionTextArea}
                                label={"Description"}
                                optional={true}
                            />
                        </div>                                                                    
                    </fieldset>

                    <div className="k-form-buttons mx-6 my-6 md:mx-0 md:mt-0 md:mb-3">
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6 k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                            Update
                        </button>
                        <button
                            type={"submit"}
                            className="k-button normal-case md:w-1/6"
                            onClick={props.cancelEdit}
                        >
                            Cancel
                        </button>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default TypesEditForm;