// React imports.
import React, {useState, useEffect} from "react";

// Kendo UI component imports.
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";
import EditCommandCell from "../components/EditCommandCell";
import TypesEditForm from "../components/TypesEditForm";

const Types = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialOpenFormValue = false;
  const initialDisplaySuccessMessage = false;
  const initialEditItemValue = {};
  
  const [openForm, setOpenForm] = useState(initialOpenFormValue);
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [editItem, setEditItem] = useState(initialEditItemValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [typesData, setTypesData] = useState(initialDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
  
  useEffect (() => {
    let mounted = true;  

    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getTypesUrl = "https://api.compliance.iaaonline.net/Admin/GetTypesList";
  
    axios.get(getTypesUrl, headers)
        .then((response) => {
            if (mounted) {                                                           
              setTypesData(response.data);          
              setDataLoading(false);
            }
        })
        .catch((error) => {                
            console.log(error);
        });
           

    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setTypesData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(typesData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  const enterEdit = (item) => {
        console.log(item);
        setOpenForm(true);
        setEditItem(item);  
  };

  const handleSubmit = (event) => {
    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const updateTypeRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateTypeRecord";
  
    axios.post(updateTypeRecordUrl, event, headers)
        .then(() => {                                                           
            const editedData = typesData.map(item => {
              if (event.typeId === item.typeId) {
                  item = { ...event };
              }
  
              return item;
            });
      
          setTypesData(editedData);
          setOpenForm(false);
          setDisplaySuccessMessage(true);
        })
        .catch((error) => {                
            console.log(error);
        });   
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const TypesEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />  
  );

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">
        <div className={!openForm ? "grid" : "hidden"}>
          <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Types</h2>
          <div className="overflow-x-hidden">
            <Grid          
              data={filterBy(typesData.slice(skip, take + skip), filter)}
              pageable={{pageSizes: true}} 
              skip={skip}
              take={take}
              filterable={true}
              filter={filter}
              total={typesData.length}
              sortable={{allowUnsort: true, mode: "multiple"}}         
              sort={sort}
              onSortChange={handleSortEvent}
              onPageChange={handlePageChangeEvent}
              onFilterChange={handleFilterChangeEvent}               
            >
              <GridNoRecords></GridNoRecords>
              <GridColumn cell={TypesEditCommandCell} filterable={false} {...(window.screen.availWidth <= 768 && {width:160})} />           
              <GridColumn field="typeId" title="Type Id" filter="numeric" {...(window.screen.availWidth <= 768 && {width:200})} />
              <GridColumn field="name" title="Name" {...(window.screen.availWidth <= 768 && {width:200})} />
              <GridColumn className="truncate" field="description" title="Description" {...(window.screen.availWidth <= 768 && {width:200})} />           
            </Grid>
          </div>
        </div>
      
        {openForm &&
          <div className="grid">
            <h2 className="font-light mx-auto mt-8 mb-4 md:mb-8 md:mt-4 md:text-3xl">Edit Type</h2>
            <TypesEditForm          
              cancelEdit={handleCancelEdit} 
              onSubmit={handleSubmit} 
              item={editItem} 
            />
          </div>
        }

        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>       
      </div>
    );
  }
};
  
export default Types;