// React imports.
import React, {useState, useEffect} from "react";

// Kendo UI component imports.
import { Grid, GridToolbar, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { orderBy, filterBy } from "@progress/kendo-data-query";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";

// Axios library imports.
import axios from "axios";

// React resize detector imports.
import ReactResizeDetector from "react-resize-detector";

// Custom component imports
import LoadingPanel from "../components/LoadingPanel";
import CheckboxCommandCell from "../components/CheckboxCommandCell";
import AddNewCommandCell from "../components/AddNewCommandCell";
import EditCommandCell from "../components/EditCommandCell";
import DisclaimersEditForm from "../components/DisclaimersEditForm";

const Disclaimers = () => {
  const initialSortSettings = [];
  const initialFilterSettings = [];
  const initialDataSettings = [];
  const initialPageSkipValue = 0;
  const initialPageTakeValue = 10;
  const initialDataLoadingValue = true;
  const initialOpenFormValue = false;
  const initialDisplaySuccessMessage = false;
  const initialEditItemValue = {};
  
  const [openForm, setOpenForm] = useState(initialOpenFormValue);
  const [dataLoading, setDataLoading] = useState(initialDataLoadingValue);
  const [editItem, setEditItem] = useState(initialEditItemValue);
  const [sort, setSort] = useState(initialSortSettings);
  const [filter, setFilter] = useState(initialFilterSettings);
  const [skip, setSkip] = useState(initialPageSkipValue);
  const [take, setTake] = useState(initialPageTakeValue);
  const [disclaimersData, setDisclaimersData] = useState(initialDataSettings);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(initialDisplaySuccessMessage);
  
  useEffect (() => {   
    let mounted = true;
    
    const headers = {
      headers:{
        "ngrok-skip-browser-warning": "skip"       
      }
    };

    const getDisclaimersUrl = "https://api.compliance.iaaonline.net/Admin/GetDisclaimersList";
  
    axios.get(getDisclaimersUrl, headers)
        .then((response) => {
            if (mounted) {                                                           
              setDisclaimersData(response.data);
              setDataLoading(false);         
            }
        })
        .catch((error) => {                
            console.log(error);
        });
           

    const cleanup = () => {mounted = false};

    return cleanup;
  }, []);

  const handleSortEvent = (e) => { 
    setSort(e.sort);
    const orderedData = orderData(e.sort);
    setDisclaimersData(orderedData);
  };

  const orderData = (sortSettings) => {
    return orderBy(disclaimersData, sortSettings);
  };

  const handleFilterChangeEvent = (e) => {   
    setFilter(e.filter);  
  };

  const handlePageChangeEvent = (e) => {      
    setSkip(e.page.skip);
    setTake(e.page.take);    
  };

  const enterEdit = (item) => {
        setOpenForm(true);
        setEditItem(item);  
  };

  const enterNew = () => {
    const item = {
      disclaimerId: 0,
      name: "",
      imagePath: "",
      link: "",
      text: "",
      active: true
    };

    setOpenForm(true);
    setEditItem(item);  
};

  const handleSubmit = (event) => {
    if (event.disclaimerId === 0) {
      const headers = {
        headers:{
          "ngrok-skip-browser-warning": "skip"       
        }
      };

      const addNewDisclaimerRecordUrl = "https://api.compliance.iaaonline.net/Admin/CreateDisclaimerRecord";
    
      axios.post(addNewDisclaimerRecordUrl, event, headers)
          .then((response) => {     
            event.disclaimerId = response.data.disclaimerId;
            
            const updatedData = disclaimersData;
            updatedData.push(event);
            
            setDisclaimersData(updatedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    } else {
      const updateDisclaimerRecordUrl = "https://api.compliance.iaaonline.net/Admin/UpdateDisclaimerRecord";
    
      axios.post(updateDisclaimerRecordUrl, event)
          .then(() => {                                                           
              const editedData = disclaimersData.map(item => {
                if (event.disclaimerId === item.disclaimerId) {
                    item = { ...event };
                }
    
                return item;
              });
        
            setDisclaimersData(editedData);
            setOpenForm(false);
            setDisplaySuccessMessage(true);
          })
          .catch((error) => {                
              console.log(error);
          });
    }   
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };
  
  const DisclaimersEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />  
  );

  const DisclaimersAddNewCommandCell = (props) => (
    <AddNewCommandCell {...props} enterNew={enterNew} />  
  );

  if (dataLoading) {
    return (
      <LoadingPanel></LoadingPanel>
    );
  }
  else {
    return (
      <div className="bg-white md:mx-16 md:mt-8 md:px-8 md:py-8 md:rounded-t-md md:shadow-lg">     
        <ReactResizeDetector handleWidth refreshMode="debounce">
          {({ width, targetRef }) => 
            <div ref={targetRef} className={!openForm ? "grid" : "hidden"}>      
              <h2 className="font-light mx-auto mt-8 mb-4 hidden md:block md:mb-8 md:mt-4 md:text-3xl">Disclaimers</h2>        
              <div className="overflow-x-hidden">
                <Grid             
                  data={filterBy(disclaimersData.slice(skip, take + skip), filter)}
                  pageable={{pageSizes: true}} 
                  skip={skip}
                  take={take}
                  filterable={true}
                  filter={filter}
                  total={disclaimersData.length}
                  sortable={{allowUnsort: true, mode: "multiple"}}         
                  sort={sort}
                  onSortChange={handleSortEvent}
                  onPageChange={handlePageChangeEvent}
                  onFilterChange={handleFilterChangeEvent}              
                >
                  <GridToolbar>
                    <DisclaimersAddNewCommandCell/>
                  </GridToolbar>
                  <GridNoRecords></GridNoRecords>
                  <GridColumn cell={DisclaimersEditCommandCell} filterable={false} {...(window.screen.availWidth <= 768 && {width:160})} />                 
                  <GridColumn field="disclaimerId" title="Disclaimer Id" filter="numeric" {...(window.screen.availWidth <= 768 && {width:200})} />
                  <GridColumn field="name" title="Name" {...(window.screen.availWidth <= 768 && {width:200})} />                          
                  <GridColumn className="truncate" field="text" title="Text" {...(window.screen.availWidth <= 768 && {width:200})} />
                  <GridColumn field="active" title="Active" filter="boolean" cell={CheckboxCommandCell} {...(window.screen.availWidth <= 768 && {width:200})}/>                                     
                </Grid>
              </div>
            </div>
          }
        </ReactResizeDetector>
      
        {openForm &&
          <div className="grid">
            <h2 className="font-light mx-auto mt-8 mb-4 md:mb-8 md:mt-4 md:text-3xl">
              {editItem.disclaimerId === 0 ? "Create Disclaimer" : "Edit Disclaimer"} 
            </h2>
            <DisclaimersEditForm          
              cancelEdit={handleCancelEdit} 
              onSubmit={handleSubmit} 
              item={editItem}
              submitAction={editItem.disclaimerId === 0 ? "Create" : "Update"} 
            />
          </div>
        }

        <Fade enter={true} exit={true}>
            {displaySuccessMessage && 
              <NotificationGroup
                style={{ bottom: 20, right: 20, alignItems: "flex-end" }}
              >
                <Notification
                  type={{ style: "success", icon: false }}             
                  closable={true}
                  onClose={() => setDisplaySuccessMessage(false)}
                >
                  <span>Your data has been saved successfully.</span>
                </Notification>
              </NotificationGroup>
            }
        </Fade>         
      </div>
    );
  }
};
  
export default Disclaimers;